import {
  Container,
  Stack,
  Flex,
  Heading,
  Text,
  Button,
  Image,
  Icon,
  // Box,
  IconProps,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { IoFlash, IoSync, IoTrendingUp, IoBody } from 'react-icons/io5';

import vendiImage from './hero.png';
// import { VendingMachine } from './vender';

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}
      >
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export function Hero() {
  return (
    <Container maxW={'7xl'}>
      <Stack
        align={'center'}
        position={'relative'}
        spacing={{ base: 8, md: 10 }}
        py={10}
        direction={{ base: 'column', md: 'row' }}
      >
        <Stack flex={2} spacing={{ base: 5, md: 10 }}>
          <Heading
            lineHeight={1.1}
            fontWeight={600}
            fontSize={{ base: '4xl', sm: '5xl', lg: '6xl' }}
          >
            <Text as={'span'} color={'blue.200'}>
              Your Venue,
            </Text>
            <br />
            <Text
              as={'span'}
              position={'relative'}
              style={{ zIndex: 1 }}
            >
              Your Revenue!
            </Text>
          </Heading>
          <Text color={'gray.500'}>
            Earn money through our simple yet effective model. We provide the
            platform, you provide the venue.
          </Text>
          <Stack spacing={4}>
            <Feature
              icon={<Icon as={IoFlash} color={'yellow.500'} w={5} h={5} />}
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'Fully offset increased energy costs!'}
            />
            <Feature
              icon={<Icon as={IoSync} color={'blue.500'} w={5} h={5} />}
              iconBg={useColorModeValue('blue.100', 'blue.900')}
              text={'Complete end-to-end service.'}
            />
            <Feature
              icon={<Icon as={IoTrendingUp} color={'green.500'} w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Revenue stream for your business.'}
            />
            <Feature
              icon={<Icon as={IoBody} color={'purple.500'} w={5} h={5} />}
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'Increase customer retention.'}
            />
          </Stack>
          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={{ base: 'column', sm: 'row' }}
          >
            <Button
              fontFamily={'heading'}
              as={'a'}
              href={'#contactUs'}
              mt={8}
              px={6}
              size={'lg'}
              bgGradient="linear(to-r, blue.200,blue.400)"
              color={'white'}
              _hover={{
                bgGradient: 'linear(to-r, blue.200,blue.400)',
                boxShadow: 'xl',
              }}
            >
              Order Free Now
            </Button>
          </Stack>
        </Stack>
        <Flex
          flex={1}
          justify={'center'}
          align={'center'}
          position={'relative'}
          style={{ zIndex: 1 }}
          w={'full'}
          h={550}
        >
          {/* <Box h={550}>
            <VendingMachine />
          </Box> */}
            <Image
              alt={'Hero Image'}
              w={'300px'}
              src={vendiImage}
              />
        </Flex>
        <Blur
          position={'absolute'}
          top={0}
          right={0}
          style={{ filter: 'blur(100px)' }}
        />
      </Stack>
    </Container>
  );
}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, sm: 0, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="70.5" cy="458.5" r="351.5" fill="#4299E1" />
      <circle cx="426.5" cy="-0.5" r="301.5" fill="#48BB78" />
    </Icon>
  );
};

export const Blob = (props: IconProps) => {
  return (
    <Icon
      width={'100%'}
      viewBox="0 0 578 440"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};
