import {
  Box,
  Stack,
  Heading,
  Text,
  Container,
  Input,
  Button,
  SimpleGrid,
  useBreakpointValue,
  IconProps,
  Icon,
} from '@chakra-ui/react';

export function Contact() {
  return (
    <Box position={'relative'} id={'contactUs'}>
      <Blur
        position={'absolute'}
        top={0}
        left={0}
        style={{ filter: 'blur(100px)' }}
      />
      <Container
        as={SimpleGrid}
        maxW={'7xl'}
        columns={{ base: 1, md: 2 }}
        spacing={{ base: 10, lg: 32 }}
        py={{ base: 10, sm: 20, lg: 32 }}
      >
        <Stack spacing={{ base: 10, md: 20 }}>
          <Heading
            lineHeight={1.1}
            fontSize={{ base: '3xl', sm: '4xl', md: '5xl', lg: '6xl' }}
            style={{zIndex: 1}}
          >
            Order a{' '}
            <Text
              as={'span'}
              bgGradient="linear(to-r, green.200,green.200)"
              bgClip="text"
            >
              free
            </Text>{' '}
            machine{' '}
            <Text
              as={'span'}
              bgGradient="linear(to-r, blue.200,blue.200)"
              bgClip="text"
            >
              &
            </Text>{' '}
            Installation
          </Heading>
        </Stack>
        <Stack
          bg={'gray.50'}
          rounded={'xl'}
          p={{ base: 4, sm: 6, md: 8 }}
          spacing={{ base: 8 }}
          maxW={{ lg: 'lg' }}
        >
          <Stack spacing={4}>
            <Heading
              color={'gray.800'}
              lineHeight={1.1}
              fontSize={{ base: '2xl', sm: '3xl', md: '4xl' }}
            >
              Contact our team!
            </Heading>
            <Text color={'gray.500'} fontSize={{ base: 'sm', sm: 'md' }}>
              We strive to deliver the best service possible!
            </Text>
          </Stack>
          <Box as={'form'} mt={10} action="https://api.web3forms.com/submit" method="POST" id="form">
              <Stack spacing={4}>
                <input type="hidden" name="access_key" value="98c43d7d-e183-4f76-9ab6-2b0b4852e858" />
                <input type="hidden" name="subject" value="New Contact" />
                <input type="hidden" name="redirect" value="https://web3forms.com/success" />
                <input type="checkbox" name="botcheck" id="" style={{display: 'none'}} />
                <Input
                  required
                  placeholder="Name"
                  name="name"
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                />
                <Input
                  required
                  placeholder="Email"
                  name="email"
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                />
                <Input
                  required
                  placeholder="Phone number"
                  name="phone"
                  bg={'gray.100'}
                  border={0}
                  color={'gray.500'}
                  _placeholder={{
                    color: 'gray.500',
                  }}
                />
              </Stack>
              <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgGradient="linear(to-r, blue.200,blue.400)"
                color={'white'}
                _hover={{
                  bgGradient: 'linear(to-r, blue.200,blue.400)',
                  boxShadow: 'xl',
                }}
                type={'submit'}
              >
                Submit
              </Button>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '30vw' })}
      zIndex={useBreakpointValue({ base: -1, sm: 0, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="70.5" cy="458.5" r="251.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="401.5" fill="#4299E1" />
    </Icon>
  );
};
