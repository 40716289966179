import {
  Container,
  Stack,
  SimpleGrid,
  Heading,
  UnorderedList,
  ListItem,
  Flex,
  Image,
} from '@chakra-ui/react';

import vendingMachineSmallImage from './vendi-small.png';
import vendingMachineStandingImage from './vendi.png';

export const Machine = () => {
  return (
    <Container maxW={'7xl'} py={{ base: 10, sm: 20, lg: 32 }}>
      <Heading>Fully featured vending machines!</Heading>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
        <Stack spacing={4} justifyContent={'center'}>
          <Stack spacing={4}>
            <UnorderedList spacing={{ base: 2, md: 4 }}>
              <ListItem>Cashless payment system.</ListItem>
              <ListItem>Optional age verification.</ListItem>
              <ListItem>Cellular 4G/5G network coverage.</ListItem>
              <ListItem>Plug and play system.</ListItem>
              <ListItem>Fully insured & licensed.</ListItem>
              <ListItem>Zero costs.</ListItem>
              <ListItem>Fully managed and serviced.</ListItem>
            </UnorderedList>
          </Stack>
        </Stack>
        <Flex
          align={'center'}
          justifyContent={{ base: 'left', md: 'right' }}
          mt={{ base: 10 }}
        >
          <Image
            src={vendingMachineSmallImage}
            maxH={{ base: 220, lg: 280, xl: 320 }}
            mr={{ base: 0, md: 10 }}
          />
          <Image
            src={vendingMachineStandingImage}
            maxH={{ base: 300, lg: 380, xl: 450 }}
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
};
