import {
  Container,
  SimpleGrid,
  Heading,
  Stack,
  Box,
  UnorderedList,
  ListItem,
  Text,
} from '@chakra-ui/react';

export function Model() {
  return (
    <Container maxW={'7xl'} py={{ base: 10, sm: 20, lg: 32 }}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={1}>
        <Stack
          spacing={8}
          align={'left'}
          direction={'column'}
          // mb={{ base: 10 }}
        >
          <Heading>How it works?</Heading>
          <Heading size={'md'}>
            Earn money for nothing while you run business as usual.
          </Heading>
          {/* <Image
            src={graphImage}
            // maxH={{ base: 220, lg: 280, xl: 320 }}
            pr={{ base: 0, sm: 10 }}
          /> */}
          <Box
            maxW={'sm'}
            borderRadius={12}
            border={'3px'}
            borderColor={'blue.200'}
            borderStyle={'solid'}
            p={15}
            spacing={4}
          >
            <Heading size={'md'}>Example:</Heading>
            <UnorderedList spacing={{ base: 2, md: 4 }} mt={5}>
              <ListItem>
                <Heading size={'sm'}>100 sales per week = £13,000</Heading>
              </ListItem>
              <ListItem>
                <Heading size={'sm'}>150 sales per week = £19,500</Heading>
              </ListItem>
              <ListItem>
                <Heading size={'sm'}>200 sales per week = £26,000</Heading>
              </ListItem>
            </UnorderedList>

            <Text mt={8}>Estimated returns per annual year</Text>
          </Box>
        </Stack>
        <Stack spacing={8} mt={{ base: 8, sm: 0 }}>
          <Heading>And theres more...</Heading>
          <Heading size={'md'}>
            We also offer digital advertising on our 32'' screens which serves
            as a potent catalyst, empowering businesses to unlock additional
            revenue streams while leveraging the vast online landscape to
            effectively reach and engage their target audience.
          </Heading>
        </Stack>
      </SimpleGrid>
    </Container>
  );
}