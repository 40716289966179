import {
  Container,
  SimpleGrid,
  Flex,
  Heading,
  Image,
  Stack,
  UnorderedList,
  ListItem,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import graphImage from './graph1.png';

export function Info() {
  return (
    <Container
      maxW={'7xl'}
      py={{ base: 10, sm: 20, lg: 32 }}
      position={'relative'}
    >
      <Heading zIndex={1}>What supports our numbers?</Heading>
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={1}>
        <Flex
          align={'left'}
          justifyContent={{ base: 'left' }}
          direction={'column'}
          mt={{ base: 10 }}
          mb={{ base: 10 }}
        >
          <Heading size={'sm'} zIndex={1}>
            UK sales by product type (2015-2022)
          </Heading>
          <Image
            src={graphImage}
            zIndex={1}
            // maxH={{ base: 220, lg: 280, xl: 320 }}
            pr={{ base: 0, sm: 10 }}
          />
        </Flex>
        <Stack spacing={4} justifyContent={'center'}>
          <UnorderedList spacing={{ base: 2, md: 4 }} zIndex={1}>
            <ListItem>
              Disposables grew by 40% between 2020 – 2021, growing by a further
              7% within 2022 to date.
            </ListItem>
            <ListItem>
              The UK Vape Channel is expected to grow to in excess of £2.4bn
              within 2022.
            </ListItem>
            <ListItem>
              Vaping has risen rapidly over the past decade to reach record
              levels in Great Britain with an estimated 4.3 million people.
            </ListItem>
            <ListItem>
              E-cigarettes are becoming especially popular among young people,
              with 18 to 24-year-olds the biggest consumers in 2022, at 11%.
              This has grown since 2021, when they had the lowest vaping rate at
              5%.
            </ListItem>
          </UnorderedList>
        </Stack>
      </SimpleGrid>
      <Blur
        position={'absolute'}
        top={'25%'}
        right={'5vw'}
        style={{ filter: 'blur(200px)' }}
      />
    </Container>
  );
}

export const Blur = (props: IconProps) => {
  return (
    <Icon
      width={useBreakpointValue({ base: '100%', md: '40vw', lg: '50vw' })}
      zIndex={useBreakpointValue({ base: -1, sm: 0, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="70.5" cy="458.5" r="351.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="301.5" fill="#4299E1" />
    </Icon>
  );
};
