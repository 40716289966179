import {
  Container,
  Box,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Heading,
  Text,
} from '@chakra-ui/react';

const Item = ({ question, answer }) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex="1" textAlign="left">
            <Heading size={'md'}>{question}</Heading>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Text>{answer}</Text>
      </AccordionPanel>
    </AccordionItem>
  );
};

const questions = [
  { question: 'What vapes do you stock?', answer: 'Elf Bar, Elux Legend\'s, Lost Mary and SKE Crystal BUT our stock changes from time to time to ensure we are offering the best products to your customers.'},
  { question: 'Is this legal at my venue?', answer: '100%, the machine and products comply with all associated legislations.'},
  { question: 'Does this promote vaping at my site?', answer: 'No, not at all. It just enhances your venue/site and makes it more appealing to customers because of the convenience. Statistics show that 1 in 7 people now vape. Customers buy inside to use outside.'},
  { question: 'What if the machine is vandalised or stops working?', answer: 'If the machine is vandalised, we will investigate the problem with a view to repair on-site in situ, the same applies to a unit not working. If in both instances the machine cannot be repaired, we will request a replacement unit for you. We operate with a 7-day-per-week support number and have someone on-site within 48 hours of the call being received.'},
  { question: 'Do I have to fill the machine or hold stock?', answer: 'As part of the proposition, we include a service level agreement at zero cost, the telemetry software inside the machine automatically sends stock fill reports every minute, which triggers auto fulfilment for each site which a keyholder can easily and safely replenish.'},
];

export const FAQ = props => {
  return (
    <Box bg={'white'}>
    <Container maxW={'7xl'} py={{ base: 10, sm: 20, lg: 32 }} color={'#030D14'}>
      <Stack spacing={4} justifyContent={'center'}>
        <Heading>Frequently Asked Questions?</Heading>
        <Accordion allowMultiple>
          {questions.map(({ question, answer }, index) => (
            <Item key={index} question={question} answer={answer}/>
          ))}
        </Accordion>
      </Stack>
    </Container>
    </Box>
  );
};
