import React from 'react';
import {
  ChakraProvider,
  Box
} from '@chakra-ui/react';
import { Header } from './components/header';
import { Info } from './components/info';
import { Hero } from './components/hero';
import { Footer } from './components/footer';
import { FAQ } from './components/faq';
import { Machine } from './components/machine';
import { Model } from './components/model';
import { Contact } from './components/contact';
// import { Perks } from './components/perks';
import theme from './theme';
function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box bg={'#030D14'}>
        <Header />
        <Hero />
        <Model />
        <Info />
        <Machine />
        {/* <Perks /> */}
        <FAQ />
        <Contact />
        <Footer />
      </Box>
    </ChakraProvider>
  );
}

export default App;
