import React from 'react';
import { Box, Heading, Flex, Container, Image } from '@chakra-ui/react';
import { FaInstagram } from 'react-icons/fa';
import { SocialButton } from '../footer';
import logo from './logo.png';
// const MenuItems = ({ children }) => (
//   <Text mt={{ base: 4, md: 0 }} mr={6} display="block">
//     {children}
//   </Text>
// );

export const Header = props => {
  // const [show, setShow] = React.useState(false);
  // const handleToggle = () => setShow(!show);

  return (
    <Container maxW={'7xl'}>
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem 0 0 0"
      bg="black.500"
      color="white"
      {...props}
    >
      <Flex
        align="center"
        mr={{ md: '5' }}
        width={{ base: '100%', md: 'auto' }}
        justifyContent={'flex-start'}
      >
        <Image src={logo} alt="Vendii Ltd" h={120} w={120} />
        
        <Heading size={{base: '2xl', sm: '3xl'}} ml={6} display="block">
          Vendii Ltd
        </Heading>

        {/* <Box display={{ sm: 'block', md: 'none' }} onClick={handleToggle}>
          <svg
            fill="white"
            width="12px"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </Box> */}
      </Flex>

      {/* <Box
        display={{ base: show ? 'block' : 'none', md: 'flex' }}
        width={{ base: '100%', md: 'auto' }}
        alignItems="center"
        flexGrow={1}
      >
        <MenuItems>Home</MenuItems>
        <MenuItems>About</MenuItems>
        <MenuItems>Contact</MenuItems>
      </Box> */}

      <Box
        display={{ base: 'none', md: 'block' }}
        mt={{ base: 4, md: 0 }}
      >
        <SocialButton label={'Instagram'} href={'https://www.instagram.com/vendii.ltd/'} h={55} w={55}>
          <FaInstagram size={30}/>
        </SocialButton>
        {/* <Button
          fontFamily={'heading'}
          as={'a'}
          href={'#contactUs'}
          px={6}
          size={'lg'}
          bgGradient="linear(to-r, blue.200,blue.400)"
          color={'white'}
          _hover={{
            bgGradient: 'linear(to-r, blue.200,blue.400)',
            boxShadow: 'xl',
          }}
        >
          Order Free Now
        </Button> */}
      </Box>
    </Flex>
    </Container>
  );
};
